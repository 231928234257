import { Check } from 'react-feather';
import React, { ReactNode, useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import SC from '../UI/SC';
import UI from '../UI';
import useDebouncedEffect from '../useDebouncedEffect';

export type NotificationType = 'error' | 'success';

interface NotificationMessage {
  id: number;
  render: ReactNode;
  type: NotificationType;
  duration?: number;
}

interface NotificationContextValue {
  notifications: NotificationMessage[];
  success: (render: ReactNode) => any;
  error: (render: ReactNode) => any;
}

interface NotificationProps {
  notification: NotificationMessage;
  hide: () => any;
}

interface NotificationContainerProps {
  type: NotificationType;
  duration?: number;
}

export const NotificationContainer = styled(UI.Card)<SC<NotificationContainerProps>>`
  ${({ sc: { type, duration } = {}, theme }) => css`
    position: relative;
    padding: 1em;
    opacity: 0;
    font-weight: 500;
    color: ${theme.colors.white};

    ${type === 'success' && css`
      background: ${theme.colors.success};

    `}

    ${type === 'error' && css`
      background: ${theme.colors.error[500]};
    `}

    ${duration && css`
      animation: fadeIn 0.5s forwards linear;
    `}

    @keyframes fadeIn {
      0% { opacity: 0; }
      30% { opacity: 1; }
      100% { opacity: 1; }
    }
  `};
`;

export const NotificationCenter = styled(UI.Div)`
  ${({ theme }) => css`
    position: fixed;
    left: ${theme.gutter}px;
    bottom: ${theme.gutter}px;
    z-index: ${theme.zIndices.notificationCenter};
  `}
`;

export const NotificationContext = React.createContext({} as NotificationContextValue);

const Notification = ({
  notification,
  hide,
}: NotificationProps) => {
  const { render, type, duration } = notification;

  useDebouncedEffect(hide, duration, []);

  return (
    <NotificationContainer sc={{ type, duration }}>
      {type === 'success' && (
        <UI.Icon sc={{ mr: 1 }}>
          <Check />
        </UI.Icon>
      )}
      {render}
    </NotificationContainer>
  );
};

interface NotificationProviderProps {
  children: ReactNode;
  maxActiveNotifications?: number;
}

let id = 0;

const NotificationProvider = ({
  maxActiveNotifications = 1, children,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);

  const elementRef = useRef<HTMLDivElement>();

  const addNotification = (notification: NotificationMessage) => {
    // Explicitly check that the component is still mounted,
    // because notifications are often added via async callbacks
    if (elementRef.current) {
      setNotifications((currentNotifications) => {
        while (currentNotifications.length >= maxActiveNotifications) {
          currentNotifications.shift();
        }

        currentNotifications.push(notification);

        return [...currentNotifications];
      });
    }
  };

  const hide = (notification: NotificationMessage) => {
    setNotifications(
      (currentNotifications) => [...currentNotifications.filter(
        (currentNotification: NotificationMessage) => currentNotification.id !== notification.id,
      )],
    );
  };

  const success = (render: ReactNode, duration: number = 3000) => {
    const notification: NotificationMessage = {
      id: id++,
      render,
      duration,
      type: 'success',
    };

    addNotification(notification);

    return () => hide(notification);
  };

  const error = (render: ReactNode, duration: number = 3000) => {
    const notification: NotificationMessage = {
      id: id++,
      render,
      type: 'error',
      duration,
    };

    addNotification(notification);

    return () => hide(notification);
  };

  const contextValue = {
    notifications,
    success,
    error,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <NotificationCenter ref={elementRef}>
        {notifications.map((notification) => (
          <Notification
            hide={() => hide(notification)}
            notification={notification}
            key={notification.id}
          />
        ))}
      </NotificationCenter>
    </NotificationContext.Provider>
  );
};

export const useNotifier = () => useContext(NotificationContext);

export default NotificationProvider;
