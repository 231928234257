import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { assetUrl, isValidLocale } from '../helpers';
import { isSupportedLocale } from '../../i18n';
import LanguageSelector from './LanguageSelector';
import LocaleProvider from '../LocaleProvider';
import UI from '../UI';
import config from '../../config';
import useUrlState from '../useUrlState';

export interface GuestLayoutProps {
  children?: ReactNode | any;
}

const GuestLayout = ({ children }: GuestLayoutProps) => {
  const { t } = useTranslation();

  const [urlState] = useUrlState({
    parse: (params) => ({
      locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : null,
    }),
  });

  return (
    <LocaleProvider locale={urlState.locale}>
      <Container>
        <UI.Div sc={{ textAlign: 'center', pt: 8 }}>
          <a href={config.baseUrl}>
            <img src={assetUrl('images/logo-color.svg')} alt={t('common:atleta')} height={24} />
          </a>
        </UI.Div>

        {children}

        <Footer />
      </Container>
    </LocaleProvider>
  );
};

export const Container = styled(UI.GridContainer)`
  ${() => css`
    min-width: 360px;
    max-width: 464px;
    margin: 0 auto;
  `}
`;

export const Footer = () => (
  <>
    <UI.HR sc={{ mx: [0, 4] }} />
    <UI.FlexContainer sc={{ justifyContent: 'space-between', alignItems: 'center', px: [3, 4], pb: 4 }}>
      <LanguageSelector />
      <a href={config.baseUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={assetUrl('images/logo-symbol-black.svg')}
          alt="Atleta"
          height={16}
          style={{ opacity: 0.3 }}
        />
      </a>
    </UI.FlexContainer>
  </>
);

export default GuestLayout;
