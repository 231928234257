import React, { useRef, useState } from 'react';

import useOnClickOutside from './useOnClickOutside';

interface UseDropdownOutput {
  isShowingOptions: boolean;
  setIsShowingOptions: React.Dispatch<React.SetStateAction<boolean>>;
  hideOptionsAnd: (callbacks?: any | undefined) => void;
  dropdownRef: React.Ref<any>;
}

const useDropdown = (): UseDropdownOutput => {
  const dropdownRef = useRef();
  const [isShowingOptions, setIsShowingOptions] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hideOptionsAnd = (...callbacks: any | undefined) => setIsShowingOptions(false);

  useOnClickOutside(dropdownRef, () => setIsShowingOptions(false));

  return {
    isShowingOptions,
    setIsShowingOptions,
    hideOptionsAnd,
    dropdownRef,
  };
};

export default useDropdown;
