import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import {
  GetCurrentUserQuery as GetCurrentUser,
  RequestLoginMutation as RequestLogin,
} from '__generated__/graphql';
import { gql } from '__generated__';

import { setWindowLocation } from '../helpers';
import SessionStorage from '../SessionStorage';
import useLocale from '../useLocale';
import useMutation from '../../api/useMutation';
import useQuery from '../../api/useQuery';

export const GetCurrentUserQuery = gql(`
  query GetCurrentUser {
    session {
      id
      user {
        ...CurrentUserFields
      }
    }
  }
`);

export const CurrentUserFieldsFragment = gql(`
  fragment CurrentUserFields on User {
    id
    email
    first_name
    last_name
    locale
  }
`);

export type User = GetCurrentUser['session']['user'];

const UserContext = createContext<{ user: User | null; loading: boolean; }>({
  user: null,
  loading: true,
});

interface UseAuthProps {
  onAuthenticated?: (user: User) => void;
  onUnauthenticated?: () => void;
}

export const useAuth = ({ onUnauthenticated, onAuthenticated }: UseAuthProps = {}) => {
  const auth = useContext(UserContext);
  const userRef = useRef<User | null>();

  useEffect(() => {
    if (auth.loading) {
      return;
    }

    if (userRef.current !== auth.user) {
      if (auth.user) {
        onAuthenticated?.(auth.user);
      } else {
        onUnauthenticated?.();
      }

      userRef.current = auth.user;
    }
  }, [auth.user, auth.loading, onAuthenticated, onUnauthenticated]);

  return auth;
};

export const UserProvider = ({ user: defaultUser, children }: { user?: User | null, children?: ReactNode; }) => {
  const { data, refetch, loading } = useQuery(GetCurrentUserQuery, {
    skip: typeof defaultUser !== 'undefined',
  });

  const user = data?.session.user || defaultUser || null;

  const history = useHistory();

  useEffect(() => data && history.listen(() => {
    refetch();
  }), [history, refetch, data]);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const ValidateUserEmailQuery = gql(`
  query ValidateUserEmail($email: String!) {
    validateUserEmail(email: $email)
  }
`);

interface UseValidateUserEmailOptions {
  requireProject?: boolean;
  skip?: boolean;
}

export const useValidateUserEmail = (email: string, options: UseValidateUserEmailOptions) => {
  const { data, loading } = useQuery(ValidateUserEmailQuery, {
    variables: {
      email,
    },
    skip: !email || options.skip,
  });

  return {
    userExists: !loading && !!data && data.validateUserEmail,
    loading,
  };
};

export const RequestLoginMutation = gql(`
  mutation RequestLogin($email: String!, $locale: Locale) {
    requestLogin(email: $email, locale: $locale)
  }
`);

export const useRequestLogin = (email: string, options: {
  onCompleted?: (loginToken: string) => void,
}) => {
  const { onCompleted = () => null } = options;
  const { locale } = useLocale();

  return useMutation(RequestLoginMutation, {
    variables: {
      email,
      locale,
    },
    onCompleted: (response: RequestLogin) => onCompleted(response.requestLogin),
  });
};

export const LoginMutation = gql(`
  mutation Login($login_token: String!, $pin: String!, $locale: Locale) {
    login(login_token: $login_token, pin: $pin, locale: $locale) {
      id
      user {
        ...CurrentUserFields
      }
    }
  }
`);

export type OnCompletedLoginCallback = (user : User | null) => void;

export const useLogin = (loginToken: string, pin: string, options: {
  onCompleted?: (user: User | null) => void,
} = {}) => {
  const { locale } = useLocale();

  return useMutation(LoginMutation, {
    variables: {
      login_token: loginToken,
      pin,
      locale,
    },
    onCompleted: (data) => options.onCompleted?.(data.login.user),
    onError: () => {},
  });
};

export const LogoutMutation = gql(`
  mutation Logout {
    logout {
      id
    }
  }
`);

export const useLogout = (options?: { onCompleted?: () => void }) => {
  const apolloClient = useApolloClient();

  return useMutation(LogoutMutation, {
    onCompleted: () => {
      apolloClient.clearStore();
      SessionStorage.clear();
      options?.onCompleted();
      setWindowLocation('/login');
    },
  });
};
