import { useEffect } from 'react';

import { inProductionEnvironment } from './helpers';
import config from '../config';

export interface AtletaEmbed {
  options?: {
    eventId?: string
  };

  initialized: boolean;
  loaded: boolean;
  iframe: () => HTMLIFrameElement;
  openWindow: () => void;
  closeWindow: () => void;
  render: () => void;
}

const useAtletaEmbed = (): AtletaEmbed => {
  useEffect(() => {
    if (!(window as any).Atleta) {
      const configScript = document.createElement('script');
      configScript.setAttribute('nonce', window.NONCE_ID);

      configScript.innerHTML = `
        window.Atleta = {
          options: {
            domain: '${config.baseUrl}',
          },
        };
      `;

      document.body.append(configScript);

      const loaderScript = document.createElement('script');
      loaderScript.setAttribute('nonce', window.NONCE_ID);
      loaderScript.async = true;

      if (inProductionEnvironment()) {
        loaderScript.src = 'https://cdn.atleta.cc/embed/widget.js';
      } else {
        loaderScript.src = `${config.baseUrl}/widget.js`;
      }

      document.body.append(loaderScript);

      return () => {
        document.body.removeChild(loaderScript);
        document.body.removeChild(configScript);
      };
    }

    return undefined;
  }, []);

  return (window as any).Atleta;
};

export default useAtletaEmbed;
