import { generatePath, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { encodeUrl } from '../../common/helpers';

type PartialRouteParams = Partial<Record<keyof RouteParams, string>>;

export const Routes = {
  // Guest
  Login: '/login',

  // Portal
  Portal: '/me',
  Account: '/me/account',

  // Registrations
  Registrations: '/p/:projectId/:participantId/events/:eventId/:token',
  UnassignedRegistrations: '/p/:projectId/:participantId/events/:eventId/:token/unassigned/:ticketId',
  Upgrades: '/p/:projectId/:participantId/events/:eventId/:token/upgrades',

  ClaimRegistration: '/p/:projectId/claim/:participantId/:eventId/registration/:registrationId/:claimToken',
  ClaimRegistrations: '/p/:projectId/claim/:participantId/:eventId/:claimToken',

  // Participant details
  Orders: '/p/:projectId/:participantId/events/:eventId/:token/orders',
  Order: '/p/:projectId/:participantId/events/:eventId/:token/orders/:orderId',
  Charity: '/p/:projectId/:participantId/charity',
};

export type RoutesKey = keyof typeof Routes;

interface RouteParams {
  projectId?: string;
  eventId?: string;
  ownerId?: string;
  participantId?: string;
  registrationId?: string;
  token?: string;
  orderId?: string;
  claimToken?: string;
  ticketId?: string;
}

interface RouteOptions {
  search?: object;
  withEmail?: string;
  withReturnUrl?: true;
}

export const useNavigation = () => {
  const routeParams = useParams<RouteParams>();

  const route = useCallback((
    routeKey: RoutesKey,
    params?: PartialRouteParams,
    options: RouteOptions = {},
  ) => {
    const path = generatePath(Routes[routeKey], { ...routeParams, ...params });

    const query = {
      ...options.search,
      ...(options.withEmail ? { email: options.withEmail } : {}),
      ...(options.withReturnUrl ? { returnUrl: window.location.href } : {}),
    };

    return Object.keys(query).length ? `${path}?${encodeUrl(query)}` : path;
  }, [routeParams]);

  return {
    r: route,
    routeParams,
  };
};
