const useShare = (data: ShareData) => {
  const handleShare = () => {
    // Handle promise rejection (e.g., user dismissed the share dialog).
    navigator.share(data).catch(() => false);
  };

  return {
    canShare: !!navigator.share,
    handleShare,
  };
};

export default useShare;
