import { Check, Copy } from 'react-feather';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import UI from '../UI';

export const useCopyLink = (value: string) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    copy(value, { format: 'text/plain' });
    setCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [copied]);

  return [handleClick, copied] as [() => void, boolean];
};

interface CopyLinkInputProps {
  value: string;
}

export const CopyLinkInput = ({ value }: CopyLinkInputProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  const [copy, copied] = useCopyLink(value);

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const { paddingRight } = input.style;
      input.style.paddingRight = `${Math.max((buttonRef.current?.offsetWidth || 0) + 10, 0)}px`;

      return () => {
        input.style.paddingRight = `${paddingRight}px`;
      };
    }

    return () => {};
  });

  return (
    <UI.InputWrapper>
      <UI.Input
        defaultValue={value}
        readOnly
        onFocus={(event) => event.target.select()}
        ref={inputRef}
        sc={{ color: 'gray.500' }}
      />
      <UI.Button
        onClick={() => copy()}
        sc={{ blank: true, noWrap: true }}
        ref={buttonRef}
        style={{ borderLeft: '1px solid rgba(0, 0, 0, .075)' }}
      >
        <UI.Icon>
          {copied ? <Check /> : <Copy />}
        </UI.Icon>
        {' '}
        {t('copy')}
      </UI.Button>
    </UI.InputWrapper>
  );
};

interface CopyLinkProps {
  value: string;
  children: ReactNode;
}

const CopyLink = ({ value, children }: CopyLinkProps) => {
  const { t } = useTranslation('common');

  const [copy, copied] = useCopyLink(value);

  return (
    <UI.A onClick={copy} title={value} sc={{ color: copied ? 'gray.500' : undefined }}>
      {!copied && (
        <>
          <UI.Icon>
            <Copy />
          </UI.Icon>
          {' '}
          {children}
        </>
      )}
      {copied && (
        <>
          <UI.Icon>
            <Check />
          </UI.Icon>
          {' '}
          {t('copy_link.copied')}
        </>
      )}
    </UI.A>
  );
};

export default CopyLink;
